@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import './assets/typography/typography.scss';
@import './assets/colors/color.scss';
@import 'mixings';

/* You can add global styles to this file, and also import other style files */
html,
body {
    font-family: Arial, Helvetica, sans-serif;
    height: 100%;
    padding: 0;
    margin: 0;
}

.qr-code {
    border: 5px solid black;
    width: fit-content;
    height: 430px;
}

@include breakpoint-down(below-elp-uhd) {
    .qr-code {
        border: 5px solid black;
        width: fit-content;
        height: 100px;
    }
}

.cdk-overlay-dark-backdrop {
    position: fixed;
    height: 100%;
}
