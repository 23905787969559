$black: #000000;
$white: #ffffff;

$primary-bg-color: #492861;
$secondary-bg-color: #653684;

$ivory: #d6caca;
$green: #009b77;
$red: #a7002f;
$dark-red-secondary: #960025;
$grey: #6e6e6e;
$light_grey_primary: #d3d3d3;
$light_grey: #efeff4;
$white_grey: #e8e6e6;
$dark_blue: #282959;
$grey_blue: #6c6b93;

$fade-bg-color-version-modal: #191919b3;
$bg-color-version-modal: #ecf1f9;

$bg-map-section: #d2d3d5;

$orange: #ea8a1a;
$airline-icon-borders-color: #00000080;

:root {
    //colors that do not change in light and dark mode (used in ts files)
    --black: #{$black};
    --white: #{$white};

    --primary-bg-color: #{$primary-bg-color};
    --secondary-bg-color: #{$secondary-bg-color};

    --ivory: #{$ivory};
    --green: #{$green};
    --red: #{$red};
    --dark-red-secondary: #{$dark-red-secondary};
    --grey: #{$grey};
    --light-grey-primary: #{$light_grey_primary};
    --white-grey: #{$white_grey};
    --light-grey: #{$light_grey};
    --dark-blue: #{$dark_blue};
    --grey-blue: #{$grey_blue};
    --fade-bg-color-version-modal: #{$fade-bg-color-version-modal};
    --bg-color-version-modal: #{$bg-color-version-modal};
    --bg-map-section: #{$bg-map-section};
    --orange: #{$orange};
    --airline-icon-borders-color: #{$airline-icon-borders-color};
}
