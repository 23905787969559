@import 'mixings';

:root {
    /* Desktop Typography */
    @include breakpoint-up(mobile) {
        /* H1+ */
        --font-size-h-1: 2rem;
        --font-weight-h-1: 600;
        --font-color-h-1: var(--dark-grey);
        --line-height-h-1: 1.15;

        /* H1 */
        --font-size-h1: 1.75rem;
        --font-weight-h1: 600;
        --font-color-h1: var(--dark-grey);
        --line-height-h1: 1.15;

        /* H2 */
        --font-size-h2: 1.35rem;
        --font-weight-h2: 600;
        --font-color-h2: var(--dark-grey);
        --line-height-h2: 1.15;

        /* H3 */
        --font-size-h3: 1.25rem;
        --font-weight-h3: 600;
        --font-color-h3: var(--dark-grey);
        --line-height-h3: 1.15;

        /* H4 */
        --font-size-h4: 1.15rem;
        --font-weight-h4: 600;
        --font-color-h4: var(--dark-grey);
        --line-height-h4: 1.15;

        /* H5 */
        --font-size-h5: 1rem;
        --font-weight-h5: 600;
        --font-color-h5: var(--dark-grey);
        --line-height-h5: 1.15;

        /* body1 */
        --font-size-body1: 1rem;
        --font-weight-body1: 400;
        --font-color-body1: var(--dark-grey);
        --line-height-body1: 1.45;

        /* body2 */
        --font-size-body2: 1rem;
        --font-weight-body2: 400;
        --font-color-body2: var(--dark-grey);
        --line-height-body2: 1.35;

        /* Button */
        --font-size-button: 1rem;
        --font-weight-button: 500;
        --font-color-button: var(--dark-grey);
        --line-height-button: 1.15;

        /* bold */
        --font-weight-bold: 700;
        --line-height-bold: 1.15;
    }
    /* Mobile Typography */
    @include breakpoint-down(mobile) {
        /* H1+ */
        --font-size-h-1: 1.75rem;
        --font-weight-h-1: 700;
        --font-color-h-1: var(--dark-grey);
        --line-height-h-1: 1.15;

        /* H1 */
        --font-size-h1: 1.5rem;
        --font-weight-h1: 700;
        --font-color-h1: var(--dark-grey);
        --line-height-h1: 1.15;

        /* H2 */
        --font-size-h2: 1.375rem;
        --font-weight-h2: 600;
        --font-color-h2: var(--dark-grey);
        --line-height-h2: 1.15;

        /* H3 */
        --font-size-h3: 1.25rem;
        --font-weight-h3: 700;
        --font-color-h3: var(--dark-grey);
        --line-height-h3: 1.15;

        /* H4 */
        --font-size-h4: 1.125rem;
        --font-weight-h4: 700;
        --font-color-h4: var(--dark-grey);
        --line-height-h4: 1.15;

        /* H5 */
        --font-size-h5: 1rem;
        --font-weight-h5: 700;
        --font-color-h5: var(--dark-grey);
        --line-height-h5: 1.5;

        /* body1 */
        --font-size-body1: 1rem;
        --font-weight-body1: 400;
        --font-color-body1: var(--dark-grey);
        --line-height-body1: 1.45;

        /* body2 */
        --font-size-body2: 0.875rem;
        --font-weight-body2: 400;
        --font-color-body2: var(--dark-grey);
        --line-height-body2: 1.35;

        /* Button */
        --font-size-button: 1rem;
        --font-weight-button: 500;
        --font-color-button: var(--dark-grey);
        --line-height-button: 1.15;

        /* bold */
        --font-weight-bold: 600;
        --line-height-bold: 1.35;
    }
}

.h-1 {
    font-size: var(--font-size-h-1);
    font-weight: var(--font-weight-h-1);
    color: var(--font-color-h-1);
    line-height: var(--line-height-h-1);
}

.h1 {
    font-size: var(--font-size-h1);
    font-weight: var(--font-weight-h1);
    color: var(--font-color-h1);
    line-height: var(--line-height-h1);
}

.h2 {
    font-size: var(--font-size-h2);
    font-weight: var(--font-weight-h2);
    color: var(--font-color-h2);
    line-height: var(--line-height-h2);
}

.h3 {
    font-size: var(--font-size-h3);
    font-weight: var(--font-weight-h3);
    color: var(--font-color-h3);
    line-height: var(--line-height-h3);
}

.h4 {
    font-size: var(--font-size-h4);
    font-weight: var(--font-weight-h4);
    color: var(--font-color-h4);
    line-height: var(--line-height-h4);
}

.h5 {
    font-size: var(--font-size-h5);
    font-weight: var(--font-weight-h5);
    color: var(--font-color-h5);
    line-height: var(--line-height-h5);
}

.body1 {
    font-size: var(--font-size-body1);
    font-weight: var(--font-weight-body1);
    color: var(--font-color-body1);
    line-height: var(--line-height-body1);
}

.body2 {
    font-size: var(--font-size-body2);
    font-weight: var(--font-weight-body2);
    color: var(--font-color-body2);
    line-height: var(--line-height-body2);
}

.button {
    font-size: var(--font-size-button);
    font-weight: var(--font-weight-button);
    color: var(--font-color-button);
    line-height: var(--line-height-button);
}

.bold {
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-bold);
}
